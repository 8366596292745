import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Grid, Box, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// style
import './style/home.css';
// router
import { useNavigate } from 'react-router-dom';
// image gallery
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// ------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const images = [
    {
        original: "assets/illustrations/texture-1909992_1280.jpg",
    },
    {
        original: "assets/illustrations/horses-2904536_1280 2.jpg",
    },
    {
        original: "assets/illustrations/lion-671193_1280 2.jpg",
    },
    {
        original: "assets/illustrations/concept-5183469_1280 2.jpg",
    },
    {
        original: "assets/illustrations/monastery-3130879_1280 2.jpg",
    },
];


export default function HomePage() {

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Home | Retoa</title>
            </Helmet>

            <StyledRoot>
                <Box >

                    <Grid container spacing={4} padding={3}
                        alignItems="center">

                        {
                            mdUp ?
                                <>
                                    <Grid item xs={4} ><Logo /></Grid>
                                    <Grid item xs={5} >
                                        <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                                            Tracciabilità dei beni culturali e opere
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} ><Button href="https://registroarte.it/login" size="large" type="reset" variant="contained"  >ACCEDI</Button></Grid>
                                    <Grid item xs={1} ><Button href="https://registroarte.it/register" size="large" type="reset" variant="outlined"  >REGISTRATI</Button></Grid>

                                </>
                                :
                                <>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={12} ><Logo /></Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={12} >
                                        <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                                            Tracciabilità dei beni culturali e opere
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={6} ><Button href="https://registroarte.it/login" size="large" type="reset" variant="contained"  >ACCEDI</Button></Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={6} ><Button href="https://registroarte.it/register" size="large" type="reset" variant="outlined"  >REGISTRATI</Button></Grid>

                                </>
                        }
                    </Grid>
                    <Box />

                    <ImageGallery
                        autoPlay={true}
                        showNav={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showIndex={false}
                        showBullets={false}
                        showPlayButton={false}
                        items={images} />

                    <div style={{ paddingLeft: '8%', paddingRight: '8%' }} >
                        <Stack padding={2} spacing={2} justifyContent="center">


                            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                                RETOA : Registro dei Titolari di Opere d’Arte, è il  registro digitale che trae spunto dal già esistente modello del Registro dei Titolari Effettivi previsto dalla V direttiva antiriciclaggio.
                                Rappresenterà  un annuario delle opere di cui si compone il patrimonio artistico-culturale di proprietà sia pubblica che privata.

                            </Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                A seguito della registrazione dell’opera e dei relativi dati, la piattaforma sarà in grado di generare un attestato digitale di titolarità della stessa.
                            </Typography>

                            <Divider />
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                L’opera validata da un esperto professionista viene registrata ed identificata con un ID, nel rispetto della GDPR, e viene generato l'attestato digitale (tecnicamente noto con il termine NFT) è autenticato su blockchain pubblica.                            </Typography>

                            <Divider />
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Un TAG (RFid-NFC) , applicato sull’opera collegata alla generazione del Titolo NFT autenticato su blockchain Pubblica. In particolare i tag saranno realizzati in modo di essere antieffrazione, non rimovibili, sigillati e irreplicabili. In ogni caso la rimozione sarebbe un atto illecito e una volta reperita un’opera senza questo sigillo, sarebbe facilmente intuibile capirne la provenienza.
                            </Typography>

                            <Divider />

                        </Stack>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center" sx={{
                                backgroundColor: 'primary.dark',
                            }} >
                            <img style={{ padding: 10, width: '50%', height: '50%' }} src={`/assets/illustrations/retoaHome.png`} alt="" />

                        </Box>

                        <Stack padding={2} spacing={2} justifyContent="center">

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Grazie al Registro dei Titolari di Opere d’Arte RETOA è possibile mettere in luce le movimentazioni aventi ad oggetto i beni culturali, evidenziando, tracciando e storicizzando i diversi passaggi di titolarità/possesso delle opere cedute e del quantitativo di denaro utilizzato nelle fattispecie transattive. Infatti solo assicurando la tracciabilità delle opere d’arte, saremo in grado di monitorare le sottostanti movimentazioni di denaro e intercettare quelle che sono provento di attività illecite.
                            </Typography>

                            <Divider />

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                La rivoluzione tecnologica che stiamo vivendo oggi, mette a disposizione dell'artista che desidera tutelare la paternità e l'evoluzione della propria opera, conoscenze e supporti infinitamente più evoluti rispetto al passato, che ne permettono finanche la sua tracciabilità nel tempo.
                            </Typography>

                            <Divider />

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Registro dei Titolari di Opere d’Arte RETOA è in grado di apportare da un punto di vista tecnologico-digitale:
                            </Typography>
                            <ul>
                                <li>tracciabilità e storicizzazione delle opere d’arte in completa dematerializzazione: è proprio la peculiarità della blockchain, cioè la capacità di tracciare e storicizzare i dati senza che alcun soggetto possa modificarne arbitrariamente il contenuto.</li>
                                <li>possibilità per il titolare di dimostrare il possesso dell’opera con un attestato digitale e portabile e quindi conseguente riduzione  dei furti di opere d’arte data la difficoltà nella rivendita:</li>
                                <li>utilizzo di tecnologia blockchain nella sua natura di registro Pubblico distribuito: la blockchain pubblica è per sua natura trasparente e disponibile in lettura a tutti, ma al tempo stesso risponde alle esigenze di privacy dei titolari dal momento che, il wallet dove è custodita l’opera, viene rappresentato solo da un codice alfanumerico.</li>
                            </ul>
                            <Divider />

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                RETOA è uno strumento totalmente digitale che consente a coloro che soffrono di disabilità motoria di poter accedere da qualsiasi supporto mobile o desktop al proprio wallet(portafoglio digitale), verificare i propri possedimenti di opere, effettuare transazioni per acquistare o vendere opere con sistema NFT da remoto. Inoltre sarà previsto un sistema vocale in grado effettuare i suddetti passaggi e quindi supportare anche coloro che soffrono di disabilità visive o motorie connesse a paralisi di arti superiori.

                            </Typography>
                            <Divider />

                            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                                Ben oltre la fiducia
                            </Typography>

                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                La Blockchain è una tecnologia simile ad un database con caratteristiche di immutabilità, sicurezza e garanzia dei dati. È riconosciuta dall’Ordinamento Giuridico Italiano nel Decreto Semplificazioni del 7 Febbraio 2019
                            </Typography>



                        </Stack>
                    </div>
                    <Box
                        display="flex"
                        padding={1}
                        justifyContent="flex-start"
                        alignItems="flex-start" sx={{
                            backgroundColor: 'primary.dark',
                        }} >

                        <Stack flex={1} spacing={2} padding={4}>
                            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                MATCHME srl viale Leopardi 153 LECCE (LE) CAP 73100 Piva 04875810758
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <Typography variant="body2" sx={{ color: 'common.white' }}>
                                RETOA è una piattaforma, di proprietà intellettuale di Solidity2 Srl, in licenza d’uso alla MATCHME S.R.L.
                            </Typography>

                            <Typography sx={{ color: 'common.white' }}>
                                <a style={{ color: 'white' }} target='blank' href='http://retoa.it/assets/Condizioni_Uso_Retoa.pdf' >Termini e condizioni</a>
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <img style={{ width: 169, height: 19 }} src="/assets/icons/logo_solidity_registroarte.png" alt="" />
                            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                © Copyright {new Date().getFullYear()} - Solidity2 srl - Startup Innovativa - P.IVA: 02454210440 – REA: AP-273622

                            </Typography>
                            <Typography sx={{ color: 'common.white' }}>
                                Email  :<a style={{ color: 'white' }} href='mailto:info@solidity2.it' >info@solidity2.it</a>
                            </Typography>
                        </Stack>
                    </Box>

                    {localStorage.getItem('cookie') === 'true' ? null :
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center" sx={{
                                backgroundColor: 'primary.dark',
                            }} >
                            <Stack id='myBtn' padding={2} spacing={2}>
                                <p>
                                    Questo sito utilizza i cookie per migliorare i servizi e ottimizzare l’esperienza di navigazione dell’utente. I cookie di natura tecnica sono indispensabili per permettere il corretto funzionamento del sito. Non utilizziamo altri tipi di cookie. Per saperne di più consulta la relativa informativa sul trattamento dei dati sulle
                                    <a style={{ color: 'white' }} target='_blank' href="https://www.retoa.it/privacy-policy" > informazioni sulla privacy</a>.
                                </p>
                                <button
                                    className='center'
                                    id='cookieBtn'
                                    onClick={() => {
                                        localStorage.setItem('cookie', 'true');
                                        window.location.reload();
                                    }}
                                >
                                    Accetta e chiudi
                                </button>
                            </Stack>
                        </Box>
                    }

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center" sx={{
                            backgroundColor: 'primary.darker',
                        }} >

                        <Stack padding={2} spacing={2}>

                            <Link target="_blank" underline='hover' href="https://www.retoa.it/privacy-policy" sx={{ color: 'common.white' }} >
                                PRIVACY
                            </Link>

                        </Stack>
                    </Box>
                </Box>
            </StyledRoot>
        </>
    )
}